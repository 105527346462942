@import '../../../../scss/theme-bootstrap';

.basic-grid-formatter {
  &__inline-wrapper {
    width: 100%;
  }
}

.homepage-wrapper {
  .basic-grid .basic-grid__item:first-child {
    .rounded-image {
      padding-top: 25px;
      @include breakpoint($portrait-up) {
        padding-top: 0;
      }
    }
  }
}
